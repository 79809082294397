<template>
  <div
    ref="tabRef"
    :class="[
      'group relative mr-1 flex min-w-fit cursor-pointer items-center justify-center gap-x-2 px-3 pb-2 text-[13px] xl:mr-3',
      isActive ? 'text-cyan-900' : 'text-gray-500 hover:text-gray-700'
    ]"
    @click="tab.handler?.(tab, index)"
  >
    <!-- Tab underline -->
    <div
      :class="[
        'absolute bottom-0 left-0 right-0 mx-auto w-10',
        isActive
          ? 'border-b-2 border-cyan-900'
          : 'group-hover:border-b-2 group-hover:border-gray-300'
      ]"
    ></div>

    <Icon
      v-if="tab.icon"
      :name="tab.icon"
      :class="[
        'h-3.5 w-3.5 transition-all',
        `${isActive ? 'scale-110 text-cyan-900' : 'text-gray-500'}`
      ]"
    />
    <span class="whitespace-nowrap transition-all">{{ tab.title }}</span>
    <Badge v-if="tab.badge" :="{ badge: tab.badge, isActive }" />
  </div>
</template>

<script setup lang="ts">
// defines
defineProps<{ tab: NavItem; index: number; isActive: boolean }>()
const emit = defineEmits(['updateWidth'])

// data
const tabRef = ref<HTMLLIElement>()

// watchers
watchEffect(() => {
  if (!tabRef.value) return
  emit('updateWidth', tabRef.value.clientWidth)
})
</script>
