<template>
  <div class="flex flex-col">
    <div :="$attrs" class="flex w-full items-center justify-between">
      <div
        ref="reference"
        :class="[
          'hide-scrollbar flex w-full overflow-auto',
          hideUnderline ? '' : 'underline-nav'
        ]"
      >
        <div
          v-for="(tab, index) in visibleTabs"
          :key="tab.key || keyFn?.(tab)"
          class="contents"
        >
          <NuxtLink
            v-if="tab.to"
            v-slot="{ isExactActive, isActive }"
            :to="tab.to"
          >
            <Tab
              :="{
                tab,
                index,
                isActive: activeTab
                  ? (tab.key || keyFn?.(tab)) === activeTab
                  : tab.exact
                  ? isExactActive
                  : isActive
              }"
              @updateWidth="(width) => updateWidths(width, index)"
            />
          </NuxtLink>
          <Tab
            v-else
            :="{
              tab,
              index,
              isActive: (tab.key || keyFn?.(tab)) === activeTab
            }"
            @updateWidth="(width) => updateWidths(width, index)"
          />
        </div>
      </div>
      <DropDown v-if="hiddenTabs.length > 0" :items="hiddenTabs">
        <Icon
          name="ellipsis"
          class="mx-3 h-5 w-5 cursor-pointer text-gray-400"
        />
      </DropDown>
    </div>
    <Transition :name="transition ?? 'page'" mode="out-in">
      <slot v-if="activeTab" :key="activeTab" :name="activeTab"></slot>
    </Transition>
  </div>
</template>

<script setup lang="ts" generic="T extends Primitive">
// defines
defineOptions({ inheritAttrs: false })
const props = defineProps<{
  tabs: NavItem[]
  keyFn?: KeyFn<NavItem>
  activeTab?: T
  hideUnderline?: boolean
  transition?: string
}>()

// data
const visibleTabs = ref(props.tabs)
const hiddenTabs = ref<NavItem[]>([])
const tabWidths = reactive<number[]>([])
const { reference, size } = useElementSize()

// methods
const updateWidths = (width: number, index: number) => {
  tabWidths[index] = width
}

// watchers
watchEffect(() => {
  let i = 0
  let totalWidth = 0
  while (tabWidths[i]) {
    if (!tabWidths[i]) continue
    totalWidth += tabWidths[i]
    if (totalWidth > size.width) {
      visibleTabs.value = props.tabs.slice(0, i)
      hiddenTabs.value = props.tabs.slice(i)
      break
    }
    i++
  }
  if (totalWidth <= size.width) {
    visibleTabs.value = props.tabs
    hiddenTabs.value = []
  }
})
</script>
